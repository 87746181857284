import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { DELETE } from 'api/request'
import * as url from 'api/urls'

interface DeleteModalProps {
  id: string
  onClose: () => void
  onReload: () => void
}
const DeleteModal: React.FC<DeleteModalProps> = ({ id, onClose, onReload }) => {
  const navigate = useNavigate()
  useEffect(() => {
    const handlePopState = () => {
      onClose()
    }

    window.history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSubmit = async () => {
    try {
      const response = await DELETE(url.deletePhoto(id))
      if (response.ok) {
        onReload()
        navigate(-1)
      } else {
        alert('W trakcie wysyłania wystąpił błąd')
      }
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <div className="modal" style={{ zIndex: '999999999' }}>
      <div className='modal__cover active' onClick={() => navigate(-1)}></div>
      <div className={`modal__content active`}>
        <div className="modal__title">
          <div className="close" onClick={() => navigate(-1)}>
            <i className="icon icon--close s24"></i>
          </div>
          <h1>Usuń zdjęcie</h1>
        </div>

        <div className="modal__body">
          <div className='modal__multipleMedia'>
            <p className='sendInfo' style={{ minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Czy na pewno chcesz usunąć zdjęcie?
            </p>
            <div className="footer footer--double">
              <div className="btn btn--secondary" onClick={() => navigate(-1)}>
                Nie, nie usuwaj
              </div>
              <div className='btn btn--primary' onClick={() => handleSubmit()}>
                Tak, usuń
              </div>
            </div>
          </div>
        </div>

      </div >
    </div >
  )
}

export default DeleteModal