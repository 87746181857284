import HeadersManager from "./HeadersManager"
import { getUuid } from "utils/uuid"

const uuid = getUuid()

interface Options {
  body?: any
  form?: any
  signal?: AbortSignal
  credentials?: boolean
}

type Method = 'GET' | 'POST' | 'DELETE' | 'PATCH'

type RequestResponse<T> = Promise<{
  ok: boolean
  status?: number
  data: T
}>

type Request<T = any> = (resource: string, method: Method, options: Options) => RequestResponse<T>

const request: Request = async (
  resource = '',
  method = 'GET',
  { body = undefined, form = undefined, signal = undefined, credentials = false }
) => {
  const token = HeadersManager.jwt.get()
  const headers = new Headers({
    'Accept': 'application/json'
  })

  headers.set('X-Client-Hash', uuid)

  if (token) headers.set('Authorization', `Bearer ${token}`)
  const init: RequestInit = {
    method,
    headers,
  }

  if (signal) init.signal = signal
  if (credentials) init.credentials = 'include'

  if (form) {
    init.body = form
  } else if (body) {
    headers.append('content-type', 'application/json')
    init.headers = headers
    init.body = JSON.stringify(body)
  }

  try {
    const response = await fetch(resource, init)
    const type = response.headers.get('content-type')
    const data = (method === 'DELETE' && !response.bodyUsed)
      ? true
      : type?.includes('json')
        ? await response.json()
        : await response.text()
    return { 
      ok: response.ok, 
      status: response.status,
      data 
    }

  } catch (error) {
    const message = error instanceof Error ? error.message : String(error)
    throw new Error(`Request failed with message: ${message}`)
  }
}

export const GET = <T = any>(resource: string, options: Options = {}): RequestResponse<T> => request(resource, 'GET', options)
export const DELETE = <T = any>(resource: string): RequestResponse<T> => request(resource, 'DELETE', { credentials: true })
export const POST = <T = any>(resource = '', options: Options = {}): RequestResponse<T> => request(resource, 'POST', options)
export const PATCH = <T = any>(resource = '', options: Options = {}): RequestResponse<T> => request(resource, 'PATCH', options)
