import SwiperGalleryModal from 'components/Modal/gallery/SwiperGalleryModal'
import { CEREMONY_ID } from 'utils/ceremonyId'
import { useEffect, useState } from 'react'
import { GET, POST } from 'api/request'
import TopBar from 'components/Topbar'
import Loader from 'components/Loader'
import * as url from 'api/urls'
import './index.scss'

const Gallery = () => {
  const [initialSlide, setInitialSlide] = useState<number>(0)
  const [data, setData] = useState<ceremonies.Ceremony>()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isModal, setModal] = useState('')

  const fetchGallery = async () => {
    setLoading(true)
    try {
      const response = await GET(`${url.ceremonies}/${CEREMONY_ID}`)
      if (response.ok) {
        setData(response.data?.data)
      } else {
        throw new Error('Failed to fetch profile')
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const reload = () => {
    fetchGallery()
  }

  useEffect(() => {
    fetchGallery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpload = async (id: string) => {
    try {
      const response = await POST(url.retryVideo(id))
      if (response.ok) {
        reload()
      } else {
        alert('W trakcie wysyłania wystąpił błąd')
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {isModal === 'gallery' && data?.gallery && <SwiperGalleryModal
        onReload={() => reload()}
        initialSlide={initialSlide}
        data={data.gallery}
        onClose={() => setModal('')}
        setData={(gallery) => {
          setData(prev => ({ ...prev!, gallery }))
        }}
      />}
      {isLoading
        ? <Loader type='simple' />
        : <div className="gallery">
          <TopBar title="Galeria" isSticky theme="dark" button />
          <div className="gallery__content">

            {data?.gallery && data?.gallery.length > 0
              ? data?.gallery.map((item, index) => (
                item.cdnStatus === 'ERROR'
                  ? <div className="videoError" onClick={() => handleUpload(item.id)}>
                    <i className="icon icon--retry"></i>
                    Spróbuj ponownie
                  </div>
                  : item.cdnStatus === 'PENDING' || item.cdnStatus === 'INIT'
                    ? <div className="videoPending">
                      <i className="icon icon--pending"></i>
                      Ładowanie
                    </div>
                    : item.cdnStatus === ''
                      ? <div className="videoPending">
                        <i className="icon icon--pending"></i>
                        Ładowanie...
                      </div>
                      :
                      <div
                        key={index}
                        className="imgWrapper"
                        onClick={() => {
                          setModal('gallery')
                          setInitialSlide(index)
                        }}>
                        <img src={item.thumbnailUrl} alt={item.displayName} loading='lazy' />
                        {item.isVideo && (
                          <div className="iconWrap">
                            <i className="icon icon--play s40"></i>
                          </div>
                        )}
                        {(item.isVideo || item.wishes !== null) &&
                          <div className="subtitle">
                            <span>{item.wishes !== null ? 'Życzenia' : item.isVideo ? 'Film' : undefined}</span>
                          </div>}
                        <div className="amounts">
                          <div><i className={`icon icon--${item.has_device_liked ? 'like-fill' : 'like'}`}></i><span>{item.likes_count}</span></div>
                          <div><i className="icon icon--comment"></i><span>{item.comments_count}</span></div>
                        </div>
                      </div>
              ))
              : <div>nic tu nie ma</div>
            }
          </div>
        </div>}

    </>)
}

export default Gallery
