import React, { createContext, useContext } from 'react'

export type UpdateModal = (_?: JSX.Element) => void

const ModalContext = createContext<UpdateModal>(() => { })

const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [, refresh] = React.useState<number>(0)
  const modal = React.useRef<JSX.Element | null>(null)

  const updateModal = React.useCallback<UpdateModal>((_modal) => {
    modal.current = _modal || null
    refresh(_modal ? Date.now() : 0)
  }, [])

  return (
    <ModalContext.Provider value={updateModal}>
      {children}
      {modal.current}
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

export {
  useModal,
  ModalProvider
}