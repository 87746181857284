import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { POST } from 'api/request'
import * as url from 'api/urls'

interface ComentsModalProps {
  data: ceremonies.Gallery
  setData: (data: ceremonies.Gallery) => void
  onClose: () => void
}

const ComentsModal: React.FC<ComentsModalProps> = ({ data, setData, onClose }) => {
  const [signature, setSignature] = useState<string>('')
  const [text, setText] = useState<string>('')

  const navigate = useNavigate()
  useEffect(() => {
    const handlePopState = () => {
      onClose()
    }

    window.history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    if (text === '') return
    try {
      const response = await POST(url.uploadComments(data.id), {
        body: {
          is_public: 1,
          is_like: 0,
          text: text,
          signature: signature === '' ? 'Anonim' : signature
        }
      })
      if (response.ok && response.data?.data) {
        setSignature('')
        setText('')
        setData(response.data.data)
      } else {
        alert('W trakcie wysyłania wystąpił błąd')
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className='commentsModal'>
      <div className="commentsModal__topBar">
        <div className="back" onClick={() => navigate(-1)}>
          <i className="icon icon--back"></i>
          <span>Wróć</span>
        </div>
       <h1>Komentarze</h1> 
      </div>
      <div className="commentsModal__body">
        {data.comments 
        ? data.comments.map(item => <div
          className='item'
          key={item.id}>
          <h2>{item.signature}</h2>
          <span>{item.text}</span>
        </div>)
        : <span className='empty'>Brak komentarzy</span>}
      </div>
      <div className="commentsModal__footer">
        <div className="input">
          <input
            type="text"
            placeholder='Podpisz się'
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
          />
        </div>
        <div className="input input--withBtn">
          <input
            type="text"
            placeholder='Wpisz komentarz...'
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button className="btn" onClick={() => handleSubmit()}>
            <i className="icon icon--send"></i>
          </button>
        </div>
      </div>

    </div>
  )
}

export default ComentsModal