import { /* useLocation */ useNavigate } from 'react-router-dom'
// import { Scanner } from '@yudiel/react-qr-scanner'
// import TopBar from 'components/Topbar'
import Loader from 'components/Loader'
// import { POST } from 'api/request'
import { useState } from 'react'
// import * as url from 'api/urls'
import './index.scss'

interface QrScannerProps {
  setQrData?: (data: QrScan) => void
}

// interface QrScanResponse {
//   data: QrScan
// }
interface QrScan {
  object: string
  id: string
  code: string
  is_owned: boolean
  qrState: 'occupied' | 'free'
  boards: board.Board[]
}

const QrScanner: React.FC<QrScannerProps> = ({ setQrData }) => {
  const [isLoading, /* setLoading */] = useState<boolean>(false)
  // const { search } = useLocation()
  const navigate = useNavigate()

  // const handleScan = (result: any) => {
  //   if (result && result[0] && result[0].rawValue) {
  //     const rawValue = result[0].rawValue
  //     const qrUrlPrefix = process.env.REACT_APP_QR_URL || 'https://app.mojekondolencje.pl/qr/'

  //     if (qrUrlPrefix && rawValue.startsWith(qrUrlPrefix)) {
  //       const hash = rawValue.slice(qrUrlPrefix.length)
  //       if (setQrData) {
  //         setLoading(true)
  //         fetchQrId(hash)
  //       } else {
  //         navigate(`/qr/${hash}${search}`)
  //       }
  //     } else {
  //       navigate('/notfound')
  //     }
  //   }
  // }

  // const fetchQrId = async (qrhash: string) => {
  //   const response = await POST<QrScanResponse>(url.qrscan, { body: { code: qrhash } })
  //   if (response.ok) {
  //     setLoading(false)
  //     setQrData?.(response.data.data)
  //   } else {
  //     throw new Error('Failed to fetch qr')
  //   }
  // }

  return (
    isLoading
      ? <Loader type='full' />
      :  <div>
        Tu będzie przycisk przejdx do skanera lub wpisz kod imprezy
        <button onClick={() => navigate('/dashboard')}>teraz kliknij tu, aby wejsc w dashboard</button>
      </div>
      // <div className="qrScanner">
      //   <TopBar title='Skanowanie...' theme='dark' isSticky={true} />
      //   <Scanner
      //     onScan={handleScan}
      //     styles={{
      //       video: {
      //         objectFit: 'cover',
      //         maxWidth: '1140px',
      //         margin: '0 auto',
      //       },
      //       finderBorder: 55
      //     }}
      //   />
      //   <div className="qrScanner__button">
      //     <button
      //       className="btn btn--secondary"
      //       onClick={() => navigate('/dashboard')}>
      //       Anuluj
      //     </button>
      //   </div>
      // </div>
  )
}

export default QrScanner