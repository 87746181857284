import TopBar from 'components/Topbar'
import './index.scss'

const Menu = () => {
  return (
    <div className="menu">
      <TopBar title="Menu" isSticky theme="dark" />
      <div className="menu__content">
        <div className="item">
          <div className="item__head">
            <h2>Zupa</h2>
            <span></span>
          </div>
          <div className="item__body">
            <p>Rosół domowy, Flaki z karpia</p>
          </div>
        </div>
        <div className="item">
          <div className="item__head">
            <h2>Obiad</h2>
            <span></span>
          </div>
          <div className="item__body">
            <p>Pstrąg w sosie chrzanowym, Pstrąg królewski z mozzarellą, Roladki z kurczaka, Polędwiczki w sosie grzybowym,
              ziemniaczki opiekane, ziemniaki gotowane, kluski śląskie, mix surówek, sałatka z sosem winegret
            </p>
          </div>
        </div>
        <div className="item">
          <div className="item__head">
            <h2>II Ciepłe danie</h2>
            <span>o 23:00</span>
          </div>
          <div className="item__body">
            <p>
              Sum z kapustą, Gulasz wieprzowy z pampuchami i ogórkami
            </p>
          </div>
        </div>
        <div className="item">
          <div className="item__head">
            <h2>III Ciepłe danie</h2>
            <span>o 2:00/2:30</span>
          </div>
          <div className="item__body">
            <p>
              Barszcz czerwony z pasztecikiem
            </p>
          </div>
        </div>
        <div className="item">
          <div className="item__head">
            <h2>Przękąski</h2>
            <span>od 19:00</span>
          </div>
          <div className="item__body">
            <p>
              Tartinki z paprykarzem sereczyńskim, Tartaletki z gzikiem, wędzoną rybą i koprem,
              Tatar ze śledzia w tartaletce, Pasta z tuńczyka z zielonym ogórkiem w nori, Pasztet w
              cieście francuskim z żurawiną, Karczek pieczony z musem chrzanowym, Polędwiczka
              wieprzowa z pesto bazyliowym, Kiełbasa wiejska z piklami i musztardą, Camembert z gruszką i balsamico, 
              Hummus z czerwoną fasolą w tartaletce, Warzywa konserwowe i marynowane, tatar z pstrąga + pieczywo
            </p>
          </div>
        </div>
        <div className="item">
          <div className="item__head">
            <h2>Sałatki</h2>
            <span>od 19:00</span>
          </div>
          <div className="item__body">
            <p>
              Sałatka z kurczakiem i zielonym ogórkiem, Sałatka z wątróbką, Sałatka caprese z pomidorami i
              bazylią
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Menu