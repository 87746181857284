import { saveState, loadState } from './storage'

const KEY_NAME = 'UUID'
const SESSION = false

export const getUuid = () => {
  let id = loadState(SESSION, KEY_NAME, '')
  if (!id) {
    id = crypto.randomUUID()
    saveState(id, SESSION, KEY_NAME)
  }
  
  return id
}