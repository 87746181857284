export function updateStatusBarColor(color) {
  const metaThemeColor = document.querySelector("meta[name=theme-color]")
  const metaAppleMobileWebAppStatusBarStyle = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]")

  if (metaThemeColor) {
    metaThemeColor.setAttribute("content", color)
  }

  if (metaAppleMobileWebAppStatusBarStyle) {
    metaAppleMobileWebAppStatusBarStyle.setAttribute("content", color === "#ffffff" ? "default" : "black-translucent")
  }
}
