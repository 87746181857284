import { useEffect, useState } from "react"
import './index.scss'

interface ModalProps {
  overflow?: boolean
  title: string
  isHistory?: boolean
  noTopPadding?: boolean
  isLargeModal?: boolean
  isLargeButton?: boolean
  modalIsCoveredByAnotherModal?: boolean
  onClose: () => void
  children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ isHistory = true, overflow=true, isLargeModal, title, noTopPadding, modalIsCoveredByAnotherModal, children, onClose }) => {
  const [isClosing, setIsClosing] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    setIsModalVisible(true)
    return () => {
      setIsModalVisible(false)
    }
  }, [])

  useEffect(() => {
    const handlePopState = () => {
      handleClose(true)
    }

    if (isHistory) {
      window.history.pushState(null, '', window.location.pathname)
    }

    if (!modalIsCoveredByAnotherModal) {
      window.addEventListener('popstate', handlePopState)
      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistory, modalIsCoveredByAnotherModal])

  const handleClose = (fromHistory?: boolean) => {
    setIsClosing(true)
    setTimeout(() => {
      setIsClosing(false)
      setIsModalVisible(false)

      if (!fromHistory && isHistory) {
        window.history.back()
      }

      onClose()
    }, 300)
  }

  return (
    <div className="modal">
      <div
        className={`modal__cover ${isModalVisible ? 'active' : ''} ${isClosing ? 'closing' : ''}`}
        onClick={modalIsCoveredByAnotherModal
          ? undefined
          : () => { handleClose(false) }}></div>
      <div style={!overflow ? {overflow: 'unset'} : undefined} className={`modal__content ${isModalVisible ? 'active' : ''} ${isClosing ? 'closing' : ''}${isLargeModal ? ' large' : ''}`}>
        <div className="modal__title">
          <div className="close" onClick={() => handleClose(true)}>
            <i className="icon icon--close s24"></i>
          </div>
          <h1>{title}</h1>
        </div>

        <div className="modal__body" style={noTopPadding ? { paddingTop: '0' } : undefined}>
          {children}
        </div>

      </div >
    </div >
  )
}

export default Modal