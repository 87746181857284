import React, { useState } from 'react'

const ImageWrapper: React.FC<ceremonies.Gallery> = ({ thumbnailUrl, url, displayName }) => {
  const [isLoaded, setLoaded] = useState(false)
  return (
    <div
      className="image-wrapper"
      style={{ position: 'relative' }}>
      {!isLoaded && <img
        src={thumbnailUrl}
        alt={displayName}
        loading='lazy'
        />}
      <img
        src={url}
        alt={displayName}
        loading='lazy'
        style={isLoaded ? undefined : { visibility: 'hidden', position: 'absolute' }}
        onLoad={() => {
          setLoaded(true)
        }} />
    </div>
  )
}

export default ImageWrapper