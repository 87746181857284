export const loadState = <T = any>(session = true, name: string, defaultVal: T): T => {
  try {
    const storage = session ? sessionStorage : localStorage
    const state = storage.getItem(name)
    return state ? JSON.parse(state) : defaultVal

  } catch (err) {
    console.log('loadState', err)
    return defaultVal
  }
}

export const saveState = <T = any>(state: T, session = true, name: string) => {
  try {
    const storage = session ? sessionStorage : localStorage
    const stringState = JSON.stringify(state)
    storage.setItem(name, stringState)

  } catch (err) {
    console.log('saveState', err)
  }
}

export const removeItem = (session = true, key: string) => {
  const storage = session ? sessionStorage : localStorage
  storage.removeItem(key)
}