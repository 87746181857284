import Sygnet from 'assets/img/sygnet.svg'
import './index.scss'

type LoaderTypes = 'full' | 'small' | 'simple'

interface LoaderProps {
  type: LoaderTypes
}
const Loader: React.FC<LoaderProps> = ({ type }) => {
  return (
    type === "full"
      ? <div className="loader__full">
        <div className="wrap">
          <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
            <circle fill="none" stroke="#000" strokeWidth="1" cx="50" cy="50" r="44" style={{ opacity: '0.5' }} />
            <circle fill="#000" stroke="none" strokeWidth="1" cx="8" cy="54" r="5" >
              <animateTransform
                attributeName="transform"
                dur="2s"
                type="rotate"
                from="0 50 48"
                to="360 50 52"
                repeatCount="indefinite" />

            </circle>
          </svg>
          <img src={Sygnet} alt='' />
        </div>
      </div>
      : type === "simple"
        ? <div className="loader__full">
          <div className="wrap">
            <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
              <circle fill="none" stroke="#000" strokeWidth="1" cx="50" cy="50" r="44" style={{ opacity: '0.5' }} />
              <circle fill="#000" stroke="none" strokeWidth="1" cx="8" cy="54" r="5" >
                <animateTransform
                  attributeName="transform"
                  dur="2s"
                  type="rotate"
                  from="0 50 48"
                  to="360 50 52"
                  repeatCount="indefinite" />

              </circle>
            </svg>
            <span>Ładowanie...</span>
          </div>
        </div>
        : <>Loading...</>
  )
}

export default Loader