import { useNavigate } from 'react-router-dom'
import './index.scss'

const Dashboard = () => {
  const navigate = useNavigate()

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '18 Urodziny Szymona, Pawła i Kuby',
          text: 'Wejdź i dodaj swoje zdjęcia!',
          url: 'https://app.snapwish.pl'
        })
      } catch (error) {
        console.error('Błąd udostępniania', error)
      }

    } else {
      alert('Niestety ta funkcja nie jest wspierana w Twojej przeglądarce. Skopiuj link z paska adresu i prześlij dalej.')
    }
  }

  return (
    <div className="dashboard">
      <div className="dashboard__main">
        <div className="title">
          <h1>18 Urodziny</h1>
          <h1>Szymona, Pawła i Kuby!</h1>
        </div>

        <div className="content">

          <div className="btn btn--big btn--white" onClick={() => navigate('/gallery')}>
            <i className="icon s48 icon--gallery"></i>
            <span>Galeria</span>
          </div>

          <div className="btn btn--big btn--white" onClick={() => navigate('/menu')}>
            <i className="icon s48 icon--menu"></i>
            <span>Menu</span>
          </div>

          <div className="btn btn--big btn--white" onClick={() => navigate('/schedule')}>
            <i className="icon s48 icon--schedule"></i>
            <span>Harmonogram</span>
          </div>

          <div className="btn btn--big btn--primary" onClick={() => navigate('/add')}>
            <i className="icon s48 icon--upload"></i>
            <span>Dodaj zdjęcia</span>
          </div>

        </div>

        <div className="showMore"
          onClick={() => {
            const moreSection = document.getElementById('more');
            moreSection?.scrollIntoView({ behavior: 'smooth' });
          }}>
          <span>Zobacz więcej</span>
          <i className="icon icon--double-arrow-down"></i>
        </div>

      </div>

      <div id='more' className="dashboard__more">
        <div className="card">
          <i className="icon s32 icon--location"></i>
          <h2>Osada Rybacka Sereczyn</h2>
          <p>95-200 Pabianice, Graniczna 46</p>
        </div>
        <div className="card">
          <i className="icon s32 icon--calendar"></i>
          <h2>16 Listopada</h2>
          <p>Rozpoczęcie imprezy o 18:00</p>
        </div>
        <div className="card" onClick={() => window.open("https://sereczyn.pl/impreza/", "_blank")}>
          <i className="icon s32 icon--play"></i>
          <h2>Kliknij, aby zagrać w grę</h2>
        </div>
        <span>Informacja dla gości</span>
        <div className="card">
          <p>Wasze filmy i zdjęcia dodadzą niepowtarzalnego
            uroku i emocji imprezie! Wrzuć swoje zdjęcia
            z imprezy i prześlij życzenia do 18.11
          </p>
          <span>~ SnapWish</span>
        </div>
        <div className="card" onClick={handleShare}>
          <i className="icon s32 icon--share"></i>
          <p>Kliknij, aby udostępnić link do wydarzenia</p>
        </div>
      </div>

      {/* <div className="dashboard__footer">
        <p>W trakcie imprezy i do dwóch dni po możecie
          wrzucić tutaj swoje zdjęcia i filmy!</p>
        <span>snapwish.pl</span>
      </div> */}

    </div>
  )
}

export default Dashboard