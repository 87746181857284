import React, { createContext, ReactNode, useState } from 'react'

interface AlertContextType {
  alert: { message: string, type: string },
  showAlert: (message: string, type: string) => void,
  hideAlert: () => void
}

export const AlertContext = createContext<AlertContextType>({
  alert: { message: '', type: '' },
  showAlert: () => {},
  hideAlert: () => {}
})

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alert, setAlert] = useState({ message: '', type: '' })

  const showAlert = (message: string, type: string) => {
    setAlert({ message, type })

    setTimeout(() => {
      setAlert({ message: '', type: '' })
    }, 5000)
  }

  const hideAlert = () => {
    setAlert({ message: '', type: '' })
  }

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  )
}
