import { Route, Routes, useLocation } from 'react-router-dom'
import { isIOS } from 'utils/nativeApp'
import QrScanner from 'pages/QrScanner'
import Dashboard from 'pages/Dashboard'
import Schedule from 'pages/Schedule'
import Notfound from 'pages/Notfound'
import Gallery from 'pages/Gallery'
import { useEffect } from 'react'
import Menu from 'pages/Menu'
import Add from 'pages/Add'
import './App.scss'
import { updateStatusBarColor } from 'utils/updateStatusBarColor'

const ios = isIOS()

function App() {

  const { pathname } = useLocation()

  useEffect(() => {
    if (
      pathname.startsWith('/gallery') ||
      pathname.startsWith('/menu') ||
      pathname.startsWith('/schedule') ||
      pathname.startsWith('/add')
    ) {
      updateStatusBarColor('#F5F4F8')
    } else {
      updateStatusBarColor('#ffffff')
    }
  }, [pathname])

  useEffect(() => {
    const updateVH = () => {
      const vh = window.innerHeight * 0.01
      document.body.style.setProperty('--vh', `${vh}px`)
    }
    updateVH()
    window.addEventListener('resize', updateVH)
    return () => {
      window.removeEventListener('resize', updateVH)
    }
  }, [])

  return (
    <main className={`app${ios ? ' --ios' : ''}`}>
      <Routes>
        <Route path='/add' element={<Add />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/' element={<Dashboard />} />
        <Route path='/' element={<QrScanner />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/schedule' element={<Schedule />} />
        <Route path='/notfound' element={<Notfound />} />
      </Routes>
    </main>
  )
}

export default App
