import { BrowserRouter as Router } from 'react-router-dom'
import { ModalProvider } from 'contexts/ModalContext'
import { AlertProvider } from 'contexts/AlertContext'
import ReactDOM from 'react-dom/client'
import Alert from 'components/Alert'
import App from './App'
import './index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  // <React.StrictMode>
  <AlertProvider>
    <Router>
      <Alert />
      <ModalProvider>
        <App />
      </ModalProvider>
    </Router>
  </AlertProvider>
  // </React.StrictMode>
)
