import { CEREMONY_ID } from 'utils/ceremonyId'
import { useNavigate } from 'react-router-dom'
import Modal from 'components/Modal/Modal'
import React, { useState } from 'react'
import { POST } from 'api/request'
import * as url from 'api/urls'

interface ModalSendWishesProps {
  onClose: () => void
}

const ModalSendWishes: React.FC<ModalSendWishesProps> = ({ onClose }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false)
  const [isSending, setSending] = useState<boolean>(false)
  const [signature, setSignature] = useState<string>('')
  const [wishes, setWishes] = useState<string>('')

  const [wishesError, setWishesError] = useState<string>('')
  const [signatureError, setSignatureError] = useState<string>('')
  const [imageError, setImageError] = useState<string>('')

  const navigate = useNavigate()

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      setSelectedImage(file)
      setImageError('')
    }
  }

  const validateFields = () => {
    let errors = false

    if (!signature.trim()) {
      setSignatureError('Podpis jest wymagany')
      errors = true
    } else {
      setSignatureError('')
    }
    if (!wishes.trim()) {
      setWishesError('Życzenia są wymagane')
      errors = true
    } else {
      setWishesError('')
    }
    if (selectedImage === null) {
      setImageError('Zdjęcie jest wymagane')
      errors = true
    } else {
      setImageError('')
    }

    return !errors
  }

  const handleSubmit = async () => {
    if (!validateFields()) {
      return
    }
    setSending(true)
    const formData = new FormData()

    if (selectedImage) formData.append(`media[0]`, selectedImage)
    formData.append('signature', signature)
    formData.append('wishes', wishes)
    formData.append('is_public', '1')

    try {
      const response = await POST(url.uploadMedia(CEREMONY_ID), {
        form: formData,
      })
      if (response.ok) {
        setInfoVisible(true)
      } else {
        alert('W trakcie wysyłania wystąpił błąd')
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSending(false)
    }
  }


  return (
    <Modal title={isInfoVisible ? 'Wysłano!' : isSending ? 'Wysyłanie...' : 'Prześlij życzenia'} onClose={onClose}>
      {isInfoVisible
        ? <div className='modal__multipleMedia'>
          <p className='sendInfo'>
            Możesz podejrzeć swoje zdjęcie z życzeniami w galerii.
          </p>
          <div className="footer footer--double">
            <div className='btn btn--primary' onClick={() => navigate('/gallery')}>
              Zobacz galerię
            </div>
          </div>
        </div>
        : <div className="modal__sendWishes">
          <div className={`input ${signatureError && 'error'}`}>
            {signatureError && <label>{signatureError}</label>}
            <input
              type="text"
              placeholder='Podpisz się'
              value={signature}
              onChange={(e) => {
                setSignature(e.target.value)
                if (signatureError) {
                  validateFields()
                }
              }} />
          </div>

          <div className={`textarea ${wishesError && 'error'}`}>
            {wishesError && <label>{wishesError}</label>}
            <textarea
              placeholder='Napisz życzenia'
              value={wishes}
              onChange={(e) => {
                setWishes(e.target.value)
                if (wishesError) {
                  validateFields()
                }
              }}
            />
          </div>

          {imageError && <label className='photo--error'>{imageError}</label>}
          <div className={`photo ${imageError && 'error'}`} onClick={() => document.getElementById('fileInput')?.click()}>
            {selectedImage
              ? <div className='imgWrap'>
                <img src={URL.createObjectURL(selectedImage)} alt="Podgląd miniaturki" style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} />
              </div>
              : <i className="icon icon--upload"></i>}
            <span>{selectedImage ? 'Kliknij, aby zmienić' : 'Kliknij i dodaj zdjęcie'}</span>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>
          <div className="footer">
            <div className={`btn btn--primary ${isSending && 'btn--sending'}`} onClick={isSending ? undefined : handleSubmit}>
              {isSending
                ? 'Wysłanie...'
                : 'Wyślij'
              }
            </div>
          </div>
        </div>}
    </Modal>
  )
}

export default ModalSendWishes