import { /* useEffect */ useState } from 'react'
import TopBar from 'components/Topbar'
import './index.scss'

// Definicja typu dla czasu wydarzenia
type EventTime = {
  hour: number
  minute: number
}

// Definicja typu dla wydarzenia
type Event = {
  time: EventTime
  title: string
}

const Schedule = () => {
  const [activeIndices, /* setActiveIndices */] = useState<number[]>([])

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const events: Event[] = [
    { time: { hour: 18, minute: 0 }, title: 'Rozpoczęcie imprezy' },
    { time: { hour: 18, minute: 30 }, title: 'Kolacja' },
    { time: { hour: 23, minute: 0 }, title: 'II Ciepłe danie' },
    { time: { hour: 0, minute: 0 }, title: 'Tort' },
    { time: { hour: 2, minute: 0 }, title: 'III Ciepłe danie' }
  ]

  // const getTimeAsDate = (time: EventTime): Date => {
  //   return new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.hour, time.minute)
  // }

  // const updateActiveEvents = () => {
  //   const now = new Date()
  //   const newActiveIndices = events
  //     .map((event, index) => (getTimeAsDate(event.time) <= now ? index : -1))
  //     .filter(index => index !== -1)

  //   setActiveIndices(newActiveIndices)
  // }

  // useEffect(() => {
  //   updateActiveEvents()

  //   const interval = setInterval(updateActiveEvents, 30000)
  //   return () => clearInterval(interval)

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <div className="schedule">
      <TopBar title="Harmonogram" isSticky theme="dark" />
      <div className="schedule__content">
        {events.map((event, index) => (
          <div
            key={index}
            className={`item ${activeIndices.includes(index) ? 'active' : ''}`}
          >
            <span>
              {event.time.hour.toString().padStart(2, '0')}:
              {event.time.minute.toString().padStart(2, '0')}
            </span>
            <div className="number">
              <div className="wrap">{index + 1}</div>
            </div>
            <h2>{event.title}</h2>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Schedule
