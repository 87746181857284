interface Share {
  title: string
  text: string
  url: string
}

interface Coordinates {
  latitude: number
  longitude: number
}

const KEY = 'NativeAppName'
export const isNativeApp = () => {
  return new RegExp(KEY).test(navigator.userAgent)
}

export const isIOS = () => {
  return isNativeApp() ? getNativeAppName() === 'ios' : false
}

export const isAndroid = () => {
  return isNativeApp() ? getNativeAppName() === 'android' : false
}

const getNativeAppName = () => {
  const regex = new RegExp(`^(.*)(${KEY})/(ios|android)(.*)$`)
  return (navigator.userAgent).replace(regex, '$3')
}

export const openAuth = (authUrl: string) => {
  if (isNativeApp()) {
    const appName = getNativeAppName()
    try {
      if (appName === 'android') {
        window.Android.openAuth(authUrl)
      } else if (appName === 'ios') {
        window.webkit.messageHandlers.OpenAuth.postMessage(authUrl)
      } else {
        throw new Error('Wrong app name')
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export const openStore = (link: string) => {
  if (isNativeApp()) {
    const appName = getNativeAppName()
    try {
      if (appName === 'android') {
        window.Android.openStore(link)
      } else if (appName === 'ios') {
        window.webkit.messageHandlers.OpenStore.postMessage(link)
      } else {
        throw new Error('Wrong app name')
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export const openMapOnAndroid = (coordinates: Coordinates) => {
  if (isAndroid()) {
    try {
      const data = JSON.stringify(coordinates)
      window.Android.openMap(data)
    } catch (error) {
      console.error(error)
    }
  }
}

export const shareOnAndroid = (share: Share) => {
  if (isAndroid()) {
    try {
      const data = JSON.stringify(share)
      window.Android.share(data)
    } catch (error) {
      console.error(error)
    }
  }
}
